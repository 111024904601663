import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
  isLoading: false,
  success: false,
  error: '',
  request: {},
  requests: [],
  histories: [],
  questionnaires: [],
  isQuestionnairesVisible: false,
  comments: [],
  activities: [],
  templateQuestionnaires: [],
  legalRequestInfo: {},
  subUsers: [],
  legalRequestUsers: [],
  uniquestRequests: [],
  counterparties: [],
  assignNewOwner: [],
  uploadTemplate: [],
  attachments: [],
  documentHistory: [],
  requestsAcrossPlatforms: {},
  totalResults: {},
  counterparty: [],
  consolidatesubscribers: [],
  lawyers: [],
  hiddenconsolidated: [],
  deletedArtefact: [],
  deletedMatter: [],
  reinstateMatter: [],
  reinstateArtefact: [],
  counterpartydetail: [],
  csvData: [],
  supplierRequestsRedux: {},
};

const legalRequestSlice = createSlice({
  name: 'legalRequest',
  initialState,
  reducers: {
    getAttachments() { },
    getAttachmentsLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    getAttachmentsSuccess(state, action) {
      state.isLoading = false;
      state.attachments = action.payload;
      state.error = '';
    },
    getAttachmentsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    uploadAttachments() { },
    uploadAttachmentsLoading(state, action) {
      state.isLoading = false;
    },
    uploadAttachmentsSuccess(state, action) {
      state.isLoading = false;
      // state.attachments.push(action.payload)
      state.error = '';
    },
    uploadAttachmentsFailure(state, action) {
      state.isLoading = false;
    },

    deleteAttachments() { },
    deleteAttachmentsLoading(state, action) {
      state.isLoading = true;
    },
    deleteAttachmentsSuccess(state, action) {
      let results = [...state.attachments];
      results = results.filter(x => x.file.id !== action.payload)
      state.attachments = results
      state.isLoading = false;
    },
    deleteAttachmentsFaliure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Save client
    fetchRequests() { },
    fetchRequestsLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchRequestsSuccess(state, action) {
      state.isLoading = false;
      state.requests = action.payload.requests;
      state.error = '';
      if (action.payload.supplierName) {
        state.supplierRequestsRedux[action.payload.supplierName] = action.payload.requests;
      }
    },
    fetchRequestsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchExpandAllRequests() { },
    fetchExpandAllRequestsLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchExpandAllRequestsSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
      state.supplierRequestsRedux = { ...state.supplierRequestsRedux, ...action.payload }
    },
    fetchExpandAllRequestsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Save client
    fetchRequestHistoryById() { },
    fetchRequestHistoryByIdLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchRequestHistoryByIdSuccess(state, action) {
      state.isLoading = false;
      state.histories = action.payload.LIFECYCLE;
      state.activities = action.payload.ACTIVITY || [];
      state.error = '';
    },
    fetchRequestHistoryByIdFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchRequestHistoryByArtefactId() { },
    fetchRequestHistoryByArtefactIdLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchRequestHistoryByArtefactIdSuccess(state, action) {
      state.isLoading = false;
      state.histories = action.payload.LIFECYCLE;
      state.activities = action.payload.ACTIVITY || [];
      state.error = '';
    },
    fetchRequestHistoryByArtefactIdFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // fetch comments by legal request history
    fetchCommentsByHistoryId() { },
    fetchCommentsByHistoryIdLoading(state) {
      state.isLoading = false;
    },
    fetchCommentsByHistoryIdSuccess(state, action) {
      state.isLoading = false;
      state.comments = action.payload;
    },
    fetchCommentsByHistoryIdFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchRequestInfo() { },
    fetchRequestInfoLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchRequestInfoSuccess(state, action) {
      state.isLoading = false;
      state.legalRequestInfo = action.payload;
      state.error = '';
    },
    fetchRequestInfoFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //fetch Users
    fetchSubUsers() { },
    fetchSubUsersLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchSubUsersSuccess(state, action) {
      state.isLoading = false;
      state.subUsers = action.payload;
      state.error = '';
    },
    fetchSubUsersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //fetch all involved users
    fetchRequestUsers() { },
    fetchRequestUsersLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchRequestUsersSuccess(state, action) {
      state.isLoading = false;
      state.legalRequestUsers = action.payload;
      state.error = '';
    },
    fetchRequestUsersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchCounterParty() { },
    fetchCounterPartyLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchCounterPartySuccess(state, action) {
      state.isLoading = false;
      state.counterparties = action.payload;
      let parties = [...action.payload];
      if (parties.length > 0) {
        parties.sort((a, b) => a.name.localeCompare(b.name))
      }
      state.counterparties = parties
      state.error = '';
    },
    fetchCounterPartyFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // consolidated counterparty filters
    fetchCounterParties() { },
    fetchCounterPartiesLoading(state, action) {
      state.isloading = true;
      state.error = ""
    },
    fetchCounterPartiesSuccess(state, action) {
      state.isloading = false;
      state.counterparty = action.payload
    },
    fetchCounterPartiesFailure(state, action) {
      state.isloading = false;
      state.error = action.payload
    },
    // consolidated subscriber filters
    fetchSubscribers() { },
    fetchSubscribersLoading(state, action) {
      state.isloading = true;
      state.error = ""
    },
    fetchSubscribersSuccess(state, action) {
      state.isloading = false;
      state.consolidatesubscribers = action.payload
    },
    fetchSubscribersFailure(state, action) {
      state.isloading = false;
      state.error = action.payload
    },

    // consolidated lawyers filters
    fetchLawyers() { },
    fetchLawyersLoading(state, action) {
      state.isloading = true;
      state.error = ""
    },
    fetchLawyersSuccess(state, action) {
      state.isloading = false;
      state.lawyers = action.payload
    },
    fetchLawyersFailure(state, action) {
      state.isloading = false;
      state.error = action.payload
    },


    saveCounterParty() { },
    saveCounterPartyLoading(state, action) {
      state.isLoading = false;
    },
    saveCounterPartySuccess(state, action) {
      state.isLoading = false;
      state.counterparties.push(action.payload)
      state.counterparties.sort((a, b) => a.name.localeCompare(b.name))
      state.error = '';
    },
    saveCounterPartyFailure(state, action) {
      state.isLoading = false;
    },
    saveModalSignedContract() { },
    saveModalSignedContractLoading(state) {
      state.isLoading = true;
    },
    saveModalSignedContractSuccess(state) {
      state.isLoading = false;   
    },
    saveModalSignedContractFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // counter Party update
    updateCounterParty() { },
    updateCounterPartyLoading(state, action) {
      state.isLoading = true;
    },
    updateCounterPartySuccess(state, action) {
      state.isloading = false;
    },
    updateCounterPartyFailure(state, action) {
      state.isloading = false;
    },

    // counter party delete
    deleteCounterParty() { },
    deleteCounterPartyLoading(state, action) {
      state.isloading = true;
    },
    deleteCounterPartySuccess(state, action) {
      state.isloading = false;
      const temp = [...state.counterparties];

      const index = _.findIndex(temp, { id: action.payload });

      if (index !== -1) {
        temp.splice(index, 1);
      }

      state.counterparties = temp;

    },
    deleteCounterPartyFailure(state, action) {
      state.isLoading = false;
    },

    // save comment
    saveCommentByRequest() { },
    saveCommentByRequestLoading(state, action) {
      state.isCommentSaveLoading = false;
    },
    saveCommentByRequestSuccess(state, action) {
      state.isCommentSaveLoading = false;
    },
    saveCommentByRequestFailure(state, action) {
      state.isCommentSaveLoading = false;
    },

    // Save client
    saveRequest() { },
    saveRequestLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    saveRequestSuccess(state) {
      state.isLoading = false;
      state.error = '';
    },
    saveRequestFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // getdeletedartefact
    deletedArtefact() { },
    deletedArtefactLoading(state) {
      state.isLoading = true;
    },
    deletedArtefactSuccess(state, action) {
      state.isLoading = false;
      state.deletedArtefact = action.payload
    },
    deletedArtefactFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // getdeletedmatters
    deletedMatter() { },
    deletedMatterLoading(state) {
      state.isLoading = true;
    },
    deletedMatterSuccess(state, action) {
      state.isLoading = false;
      state.deletedMatter = action.payload
    },
    deletedMatterFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // reinstate matter 
    reinstateMatter() { },
    reinstateMatterLoading(state) {
      state.isloading = true;
    },
    reinstateMatterSuccess(state, action) {
      state.isloading = false;
      state.reinstateMatter = action.payload;
    },
    reinstateMatterFailure(state, action) {
      state.isloading = false;
      state.error = action.payload
    },

    // reinstate artefacts
    reinstateArtefacts() { },
    reinstateArtefactsLoading(state) {
      state.isloading = true;
    },
    reinstateArtefactsSuccess(state, action) {
      state.isloading = false;
      state.reinstateArtefact = action.payload;
    },
    reinstateArtefactsFailure(state, action) {
      state.isloading = false;
      state.error = action.payload
    },

    // save lawyers
    saveLawyers() { },
    saveLawyerLoading(state) {
      state.isLoading = true;

    },
    saveLawyerSuccess(state, action) {
      state.isloading = false;
      state.error = '';
    },
    saveLawyerFailure(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },
    // save template and get questionnaire
    saveContractTemplate() { },
    saveContractTemplateLoading(state) {
      state.isLoading = true;
    },
    saveContractTemplateSuccess(state, action) {
      state.isLoading = false;
      state.isQuestionnairesVisible = action.payload ? true : false;
      state.templateQuestionnaires = action.payload ? action.payload : [];
    },
    saveContractTemplateFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Save questionnaires
    saveQuestionnaires() { },
    saveQuestionnairesLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    saveQuestionnairesSuccess(state) {
      state.isLoading = false;
      state.isQuestionnairesVisible = false;
      state.error = '';
    },
    saveQuestionnairesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    openCloseQuestionnaire() { },
    toggleQuestionnaires() { },
    toggleQuestionnairesSuccess(state, action) {
      state.isQuestionnairesVisible = action.payload;
    },

    // invite guests
    inviteGuest() { },
    inviteGuestLoading(state, action) {
      state.isLoading = true;
    },
    inviteGuestSuccess(state, action) {
      state.isLoading = false;
    },
    inviteGuestFailure(state, action) {
      state.isLoading = false;
    },

    // invite sub user
    inviteSubUser() { },
    inviteSubUserLoading(state, action) {
      state.isLoading = true;
      state.error = '';
    },
    inviteSubUserSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
    },
    inviteSubUserFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // notify
    notify() { },
    notifyLoading(state, action) {
      state.isLoading = true;
    },
    notifySuccess(state, action) {
      state.isLoading = false;
    },
    notifyFailure(state, action) {
      state.isLoading = false;
    },

    // mark complete
    markComplete() { },
    markCompleteLoading(state, action) {
      state.isLoading = true;
    },
    markCompleteSuccess(state, action) {
      state.isLoading = false;
      const tempRequests = { ...state.requests }
      const index = _.findIndex(tempRequests.results, { entityId: action.payload });

      if (index !== -1) {

        const tempResults = [...tempRequests.results];

        tempResults[index].isCompleted = true;

        tempRequests.results = tempResults;

        state.requests = tempRequests;
      }

    },
    markCompleteFailure(state, action) {
      state.isLoading = false;
    },


    markArtefactComplete() { },
    markArtefactCompleteLoading(state, action) {
      state.isLoading = true;
    },
    markArtefactCompleteSuccess(state, action) {
      state.isLoading = false;
      const artIndex = _.findIndex(state.legalRequestInfo.artefacts, { id: action.payload.artefactId });
      state.legalRequestInfo.artefacts[artIndex].isCompleted = true
      // if(state.legalRequestInfo.artefacts.filter(x => x.isCompleted != true) < 1){
      //   // mark matter are conpleted
      //   const legalReqId = _.findIndex(state.requests.results, { entityId: action.payload.legalRequestId });
      //   state.requests.results[legalReqId].isCompleted = true
      // }
    },
    markArtefactCompleteFailure(state, action) {
      state.isLoading = false;
    },

    // assign contract
    assignLegalRequest() { },
    assignLegalRequestLoading(state, action) {
      state.isLoading = true;
    },
    assignLegalRequestSuccess(state, action) {
      state.isLoading = false;
      const temp = { ...state.requests };
      const results = (state.requests.results && state.requests.results.length > 0) ? [...state.requests.results] : [];

      const index = _.findIndex(results, { entityId: action.payload });

      if (index !== -1) {
        results[index].accessRight = "READ";
      }

      temp.results = results;

      state.requests = temp;

    },
    assignLegalRequestFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // download contract document
    downloadLegalRequestDocument() { },
    downloadLegalRequestDocumentLoading(state, action) {
      state.isLoading = true;
    },
    downloadLegalRequestDocumentSuccess(state, action) {
      state.isLoading = false;
    },
    downloadLegalRequestDocumentFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // upload contract document
    uploadLegalRequestDocument() { },
    uploadLegalRequestDocumentLoading(state, action) {
      state.isLoading = true;
    },
    uploadLegalRequestDocumentSuccess(state, action) {
      state.isLoading = false;
    },
    uploadLegalRequestDocumentFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // delete legal request
    deleteLegalRequestDocument() { },
    deleteLegalRequestDocumentLoading(state, action) {
      state.isLoading = true;
    },
    deleteLegalRequestDocumentSuccess(state, action) {

      const temp = { ...state.requests };
      const results = [...state.requests.results];

      const index = _.findIndex(results, { entityId: action.payload });

      if (index !== -1) {
        results.splice(index, 1);
      }

      temp.results = results;

      state.requests = temp;

      state.isLoading = false;
    },
    deleteLegalRequestDocumentFaliure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchAssignNewOwner() { },
    fetchAssignNewOwnerLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchAssignNewOwnerSuccess(state, action) {
      state.isLoading = false;
      state.assignNewOwner = action.payload;
      state.error = '';
    },
    fetchAssignNewOwnerFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    fetchUploadArtefactById() { },
    fetchUploadArtefactByIdLoading(state, action) {
      state.isLoading = true;
    },
    fetchUploadArtefactByIdSuccess(state, action) {
      state.isLoading = false;
      //state.isQuestionnairesVisible = action.payload ? true : false;
      //state.uploadTemplate = action.payload;

      state.isQuestionnairesVisible = action.payload ? true : false;
      state.templateQuestionnaires = action.payload ? action.payload : [];
    },
    fetchUploadArtefactByIdFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addNewArtefact() { },
    addNewArtefactLoading(state, action) {
      state.isLoading = true;
    },
    addNewArtefactSuccess(state, action) {
      state.isLoading = false;
      state.legalRequestInfo.artefacts.push(action.payload)
    },
    addNewArtefactFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchSaveArtefact() { },
    fetchSaveArtefactLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchSaveArtefactSuccess(state, action) {
      state.isLoading = false;
      state.isQuestionnairesVisible = false;
      state.error = '';
    },
    fetchSaveArtefactFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchUploadArtifactDoc() { },
    fetchUploadArtifactDocLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchUploadArtifactDocSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
    },
    fetchUploadArtifactDocFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchDownloadArtifactDoc() { },
    fetchDownloadArtifactDocLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    fetchDownloadArtifactDocSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
    },
    fetchDownloadArtifactDocFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchDownloadArtifactDocById() { },

    deleteLegalRequestUser() { },
    deleteLegalRequestUserLoading(state, action) {
      state.isLoading = true;
    },
    deleteLegalRequestUserSuccess(state, action) {

      // const temp = { ...state.requests };
      // const results = [...state.requests.results];

      // const index = _.findIndex(results, { entityId: action.payload });

      // if (index !== -1) {
      //   results.splice(index, 1);
      // }

      // temp.results = results;

      // state.requests = temp;

      state.isLoading = false;
      state.error = '';
    },
    deleteLegalRequestUserFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    deleteArtefact() { },
    deleteArtefactLoading(state, action) {
      state.isLoading = true;
    },
    deleteArtefactSuccess(state, action) {
      state.isLoading = false;
    },
    deleteArtefactFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateArtefactSupportingData() { },
    updateArtefactSupportingDataLoading(state, action) {
      state.isLoading = true;
    },
    updateArtefactSupportingDataSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
    },
    updateArtefactSupportingDataFaliure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    uploadMediaFile() { },
    uploadMediaFileLoading(state) {
      state.isLoading = true;
    },
    uploadMediaFileSuccess(state, action) {
      state.isLoading = false;
    },
    uploadMediaFileFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    deleteMediaFile() { },
    deleteMediaFileLoading(state) {
      state.isLoading = true;
    },
    deleteMediaFileSuccess(state, action) {
      state.isLoading = false;
    },
    deleteMediaFileFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateLegalRequestName() { },
    updateLegalRequestNameLoading(state, action) {
      state.isLoading = true;
    },
    updateLegalRequestNameSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
    },
    updateLegalRequestNameFaliure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchDocumentHistory() { },
    fetchDocumentHistorySuccess(state, action) {
      state.isLoading = false;
      state.error = '';
      state.documentHistory = action.payload.history
    },

    updateArtefactStatus() { },

    fetchRequestAcrossPlatforms() { },
    fetchRequestAcrossPlatformsLoading(state, action) {
      state.isLoading = true;
    },
    fetchRequestAcrossPlatformsSuccess(state, action) {
      state.isLoading = false;
      state.requestsAcrossPlatforms = action.payload.data
    },
    fetchRequestAcrossPlatformsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // csv data 
    fetchCsvData() { },
    fetchCsvDataLoading(state, action) {
      state.isLoading = true;
    },
    fetchCsvDataSuccess(state, action) {
      state.isLoading = false;
      state.csvData = action.payload.data
    },
    fetchCsvDataFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    bulkArtefactSupportingDataUpdate() { },

    bulkArtefactSupportingDataUpdateLoading(state) {
      state.isLoading = true;
    },

    bulkArtefactSupportingDataUpdateSuccess(state, action) {
      state.isLoading = false;
    },

    bulkArtefactSupportingDataUpdateFailure(state, action) {
      state.isLoading = false;
    },

    updateConsolidatedFields() { },

    updateConsolidatedFieldsLoading(state) {
      state.isloading = true;
    },
    updateConsolidatedFieldsSuccess(state, action) {
      state.isloading = false;
    },
    updateConsolidatedFieldsFailure(state, action) {
      state.isloading = false;
    },


    fetchCounterPartyDetail() { },
    fetchCounterPartyDetailLoading(state, action) {
      state.isLoading = true;
    },
    fetchCounterPartyDetailSuccess(state, action) {
      state.isLoading = false;
      state.counterpartydetail = action.payload;
    },
    fetchCounterPartyDetailFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

  },
});

export const { actions, reducer, name: sliceKey } = legalRequestSlice;
