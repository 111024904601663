import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
  isLoading: false,
  success: false,
  error: '',
  client: {},
  clients: [],
  subscribers: {},
  fileUploadProgress: {},
  permissions: [],
  roles: [],
  subusers: [],
  paymentInfo: {},
  transactions: [],
  cexTemplates: [],
  lawyers:[],
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    fetchClients() { },
    clientsLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    clientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
      state.error = '';
    },
    clientsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // get subscribers
    fetchSubscribers() { },
    subscribersLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    subscribersSuccess(state, action) {
      state.isLoading = false;
      state.subscribers = action.payload;
      state.error = '';
    },
    subscribersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //create subscriber
    saveSubscriber() { },
    saveSubscriberLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    saveSubscriberSuccess(state, action) {
      state.isLoading = false;
      state.subscribers.push(action.payload);
      state.error = '';
    },
    saveSubscriberFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // get individual client
    fetchClient() { },
    clientLoading(state) {
      state.isLoading = true;
      state.client = {};
      state.error = '';
    },
    clientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
      state.error = '';
    },
    clientFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    // Save client
    saveClient() { },
    saveClientLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    saveClientSuccess(state, action) {
      state.isLoading = false;
      state.clients.push(action.payload);
      state.error = '';
    },
    saveClientFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Save client branding
    updateClient() { },
    updateClientLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    updateClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
      state.error = '';
    },
    updateClientFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    completeClientCustomization() { },
    // update lawyers
    updateClientLawyers() { },
    updateClientLawyersLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    updateClientLawyersSuccess(state, action) {
      state.isLoading = false;
      state.lawyers = action.payload;
      state.error = '';
    },
    updateClientLawyersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Save client branding
    updateSubusers() { },
    updateSubusersLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    updateSubusersSuccess(state, action) {
      state.isLoading = false;
      state.subusers = action.payload;
      state.error = '';
    },
    updateSubusersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // delete subuser
    deleteSubUser() { },
    deleteSubUserLoading(state, action) {
      state.isLoading = true;
    },
    deleteSubUserSuccess(state, action) {
      state.isLoading = false;

      const temp = [...state.subusers];

      const index = _.findIndex(temp, { id: action.payload });

      if (index !== -1) {
        temp.splice(index, 1);
      }

      state.subusers = temp;

    },
    deleteSubUserFailure(state, action) {
      state.isLoading = false;
    },

    // Save client branding
    updateClientImage() { },
    updateClientImageLoading(state) {
      state.isLoading = true;
      state.error = '';
    },
    updateClientImageSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
      state.error = '';
    },
    updateClientImageFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Save client branding
    unSelectClient() { },
    unSelectClientSuccess(state) {
      state.isLoading = false;
      state.client = {};
      state.error = '';
    },

    // file upload progress
    uploadProgress(state, action) {
      const temp = { ...state.fileUploadProgress, [action.payload.type]: action.paylaod.progress };

      state.fileUploadProgress = temp;
    },

    // get parents permissions
    getPermissions() { },
    getPermissionsLoading(state, action) {
      state.isLoading = false;
    },
    getPermissionsSuccess(state, action) {
      state.isLoading = false;
      state.permissions = action.payload;
    },
    getPermissionsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // save role
    fetchClientRoles() { },
    fetchClientRolesLoading(state, action) {
      state.isLoading = true;
    },
    fetchClientRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
    fetchClientRolesFailure(state, action) {
      state.isLoading = false;
    },

    // save role
    createRole() { },
    createRoleLoading(state, action) {
      state.isLoading = true;
    },
    createRoleSuccess(state, action) {
      state.isLoading = false;
      state.roles.push(action.payload);
    },
    createRoleFailure(state, action) {
      state.isLoading = false;
    },

    // update role
    updateRole() { },
    updateRoleLoading(state, action) {
      state.isLoading = true;
    },
    updateRoleSuccess(state, action) {
      state.isLoading = false;
      const temp = [...state.roles];

      const index = _.findIndex(temp, { id: action.payload.id });

      if (index !== -1) {
        temp[index] = action.payload;
      }
      state.roles = temp;
    },
    updateRoleFailure(state, action) {
      state.isLoading = false;
    },

    // delete role
    deleteRole() { },
    deleteRoleLoading(state, action) {
      state.isLoading = true;
    },
    deleteRoleSuccess(state, action) {
      state.isLoading = false;
      const temp = [...state.roles];

      const index = _.findIndex(temp, { id: action.payload });

      if (index !== -1) {
        temp.splice(index, 1);
      }

      state.roles = temp;

    },
    deleteRoleFailure(state, action) {
      state.isLoading = false;
    },

    getSubUsersByParentId() { },
    getSubUsersByParentIdLoading(state,) {
      state.isLoading = true;
    },
    getSubUsersByParentIdSuccess(state, action) {
      state.isLoading = false;
      state.subusers = action.payload;
    },
    getSubUsersByParentIdFailure(state, action) {
      state.isLoading = false;
      state.error = action.paylaod;
    },

    getSubUsersWithParent() { },
    getSubUsersWithParentLoading(state,) {
      state.isLoading = true;
    },
    getSubUsersWithParentSuccess(state, action) {
      state.isLoading = false;
      state.subusers = action.payload;
    },
    getSubUsersWithParentFailure(state, action) {
      state.isLoading = false;
      state.error = action.paylaod;
    },

    // resend invitation
    resendInvite() { },
    resendInviteLoading(state, action) {
      state.isLoading = true;
    },
    resendInviteSuccess(state, action) {
      state.isLoading = false;
    },
    resendInviteFailure(state, action) {
      state.isLoading = false;
    },

    fetchPaymentInfo() { },
    fetchPaymentInfoLoading(state) {
      state.isLoading = true;
    },
    fetchPaymentInfoSuccess(state, action) {
      state.isLoading = false;
      state.paymentInfo = action.payload;
    },
    fetchPaymentInfoFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchTransactions() { },
    fetchTransactionsLoading(state) {
      state.isLoading = true;
    },
    fetchTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.transactions = action.payload;
    },
    fetchTransactionsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchCExTemplatesLoading(state) {
      state.isLoading = false;
    },
    fetchCExTemplatesSuccess(state, action) {
      state.isLoading = false;
      state.cexTemplates = action.payload;
    },
    fetchCExTemplatesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = clientsSlice;
