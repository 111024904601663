import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
  isLoading: false,
  success: false,
  error: '',
  signedContractList: [],
  selctedSignedContract: {},
  metaDataList: {},
  allUsedSupportingFields: [],
  hiddenMetaField: [],
  totalResults: {},
  signedContractDataList: {},
  allUsedSignedContractSupportingFields: [],
  hiddenSignedContractField: [],
  hiddenConsolidatedField: [],
  totalSignedContractResults: {},
};

const signedContractSlice = createSlice({
  name: 'signedContract',
  initialState,
  reducers: {

    // fetch all signed contract
    fetchAdminSignedContract() { },
    signedContractLoading(state, action) {
      state.isLoading = true;
    },
    signedContractSuccess(state, action) {
      state.isLoading = false;
      state.signedContractList = action.payload;
      state.error = '';
    },
    signedContractFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // save admin signed contract
    saveSignedContract() { },
    saveSignedContractLoading(state, action) {
      state.isLoading = true;
    },
    saveSignedContractSuccess(state, action) {
      state.isLoading = false;
    },
    saveSignedContractFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // fetch on signed contract  by id
    fetchAdminSignedContractById() { },
    signedContractByIdLoading(state, action) {
      state.isLoading = true;
    },
    signedContractByIdSuccess(state, action) {
      state.isLoading = false;
      state.selctedSignedContract = action.payload;
      state.error = '';
    },
    signedContractByIdFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // update signed contract
    updateSignedContract() { },
    updateSignedContractLoading(state, action) {
      state.isLoading = true;
    },
    updateSignedContractSuccess(state, action) {
      state.isLoading = false;
    },
    updateSignedContractFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // delete signed contract 

    deleteSignedContact() { },
    deleteSignedContactLoading(state, action) {
      state.isLoading = true;
    },
    deleteSignedContactSuccess(state, action) {
      const tempSignedContract = [...state.signedContractList.results];

      const index = _.findIndex(tempSignedContract, { id: action.payload.id });
      if (index !== -1) {
        tempSignedContract.splice(index, 1);
        Object.assign(state.signedContractList, { results: tempSignedContract });
      }
      state.isLoading = false;
    },
    deleteSignedContactFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // Meta Data List 

    fetchMetaDataList() { },
    metaDataListLoading(state, action) {
      state.isLoading = true;
    },
    metaDataListSuccess(state, action) {

      if (action.payload.type == 'total') {
        //console.log("In slice conndition==>", action.payload)
        state.totalResults = action.payload.data
      } else {
        state.isLoading = false;
        state.metaDataList = action.payload.data
      }
      state.error = ''
    },
    metaDataListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    // Signed Contract Data List 

    fetchSignedContractDataList() { },
    signedContractDataListLoading(state, action) {
      state.isLoading = true;
    },
    signedContractDataListSuccess(state, action) {

      if (action.payload.type == 'total') {
        //console.log("In slice conndition==>", action.payload)
        state.totalSignedContractResults = action.payload.data
      } else {
        state.isLoading = false;
        state.signedContractDataList = action.payload.data
      }
      state.error = ''
    },
    signedContractDataListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },


    fetchAllUsedSupportingFields() { },
    allUsedSupportingFieldsLoading(state, action) {
      state.isLoading = true;
    },
    allUsedSupportingFieldsSuccess(state, action) {
      state.isLoading = false;
      state.allUsedSupportingFields = action.payload
      state.error = ''
    },
    allUsedSupportingFieldsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    fetchSignedContractSupportingFields() { },
    signedContractSupportingFieldsLoading(state, action) {
      state.isLoading = true;
    },
    signedContractSupportingFieldsSuccess(state, action) {
      state.isLoading = false;
      state.allUsedSignedContractSupportingFields = action.payload
      state.error = ''
    },
    signedContractSupportingFieldsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    getMetaFieldSelection() { },
    getMetaFieldSelectionLoading(state, action) {
      state.isLoading = true;
    },
    getMetaFieldSelectionSuccess(state, action) {
      // console.log("----->>", action)
      state.isLoading = false;
      state.hiddenMetaField = action.payload.user.metaDataFields
      state.hiddenSignedContractField = (action.payload.user.signedMetaDataFields) ? action.payload.user.signedMetaDataFields : []
      state.hiddenConsolidatedField = (action.payload.user.consolidatedFieldVisibility) ? action.payload.user.consolidatedFieldVisibility : []

      state.error = ''
    },
    getMetaFieldSelectionFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    updateMetaFieldSelection() { },
    updateMetaFieldSelectionLoading(state, action) {
      state.isLoading = true;
    },
    updateMetaFieldSelectionSuccess(state, action) {
      state.isLoading = false;
      state.error = ''
    },
    updateMetaFieldSelectionFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },

    updateSignedContractFieldSelection() { },
    updateSignedContractFieldSelectionLoading(state, action) {
      state.isLoading = true;
    },
    updateSignedContractFieldSelectionSuccess(state, action) {
      state.isLoading = false;
      state.error = ''
    },
    updateSignedContractFieldSelectionFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },
    downloadSampleFile() { },

    uploadZip() { },
    uploadZipLoading(state, action) {
      state.isLoading = true;
    },
    uploadZipSuccess(state, action) {
      state.isLoading = false;
      state.error = ''
    },
    uploadZipError(state, action) {
      state.isLoading = false;
      state.error = action.payload
    }
  },
});

export const { actions, reducer, name: sliceKey } = signedContractSlice;
