import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { request } from '../../../utils/request';
import Toast from '../../../services/toastService';
import userService from '../../../services/userService';
import { removeTheme, saveTheme } from '../../../services/themeService';
import { isAdmin } from '../../../services/authService';
import localforage from 'localforage';
import paths from '../../../config/paths';
import { delayedRedirect } from '../../../utils/response';

export function* registerUser(action) {
  yield put(actions.registerUserLoading());

  try {
    const data = yield call(
      request,
      `auth/register`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.registerUserSuccess(data.user));
    userService.setIsOTPVerification(false);
    window.location.href = `/complete-signup/${window.btoa(data.user.id)}`
  } catch (e) {
    yield put(actions.registerUserFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(e.toString());
  }
}

export function* registerUserWithAzure(action) {
  yield put(actions.registerUserLoading());

  try {
    const data = yield call(
      request,
      `auth/outlook/login`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.registerUserSuccess(data.user));
    userService.setIsOTPVerification(false);
   // window.location.href = `/complete-signup/${window.btoa(data.user.id)}`
    if (!data.user.registrationStepsComplete) {
      yield call(delayedRedirect, `/complete-signup/${window.btoa(data.user.id)}`);
    } else {
      Toast.success('You are successfully logged in');

      yield call(userService.saveUserToken, data.tokens.access, 'access');
      yield call(userService.saveUserToken, data.tokens.refresh, 'refresh');
      yield call(userService.saveUser, data.user);

      yield call(userService.setIsOTPVerification, false);

      yield call(delayedRedirect, '/');
    }
  } catch (e) {
    yield put(actions.registerUserFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(e.toString());
  }
}

export function* completeRegistration(action) {
  yield put(actions.completeRegistrationLoading());

  try {
    const data = yield call(
      request,
      `auth/complete-registration/${action.payload.userId}`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.completeRegistrationSuccess(data.user));
    window.location.href = `/login?state=registration-complete`
  } catch (e) {
    yield put(actions.completeRegistrationFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(e.toString());
  }
}

export function* emailVerification(action) {
  yield put(actions.emailVerificationLoading());

  try {
    yield call(
      request,
      `auth/verify-email?token=${action.payload.token}`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.emailVerificationSuccess());
    window.location.href = `/login?state=email-verified`;
  } catch (e) {
    yield put(actions.emailVerificationFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while logging in!');
  }
}

export function* loginUser(action) {
  yield put(actions.loginUserLoading());

  try {
    const data = yield call(
      request,
      `auth/login`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.loginUserSuccess(data.user));
    Toast.success('You are successfully logged in');

    yield call(userService.saveUserToken, data.tokens.access, 'access');
    yield call(userService.saveUserToken, data.tokens.refresh, 'refresh');
    yield call(userService.saveUser, data.user);

    let themeData = {};

    const isUserAdmin = yield call(isAdmin);

    // apply theme
    if (!isUserAdmin) {

      themeData = {
        primaryColor: data.user.branding.primaryColor,
        secondaryColor: data.user.branding.secondaryColor,
        textColor: data.user.branding.textColor,
        logo: data.user.branding.logo.image !== "" ? `${paths.serverPublicUrl}${data.user.branding.logo.path}${data.user.branding.logo.image}` : null,
        favicon: data.user.branding.favicon.image !== "" ? `${paths.serverPublicUrl}${data.user.branding.favicon.path}${data.user.branding.favicon.image}` : null,
      };

      yield call(saveTheme, 'theme', themeData);

    }
    localforage.setItem('expand', true);
    if (!data.user.isAdmin && data.user.isOTPEnabled) {
      userService.setIsOTPVerification(true);
      yield call(delayedRedirect, '/verify-otp');
    } else if (data.user.isAdmin) {
      userService.setIsOTPVerification(false);
      yield call(delayedRedirect, '/admin/management-center');
    } else {
      yield call(delayedRedirect, '/');
    }

  } catch (e) {
    yield put(actions.loginUserFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while logging in!');
  }
}

export function* loginUserWithAzure(action) {
  yield put(actions.loginUserLoading());

  try {
    const data = yield call(
      request,
      `auth/outlook/login`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.loginUserSuccess(data.user));

    if (!data.user.registrationStepsComplete) {
      yield call(delayedRedirect, `/complete-signup/${window.btoa(data.user.id)}`);
    } else {
      Toast.success('You are successfully logged in');

      yield call(userService.saveUserToken, data.tokens.access, 'access');
      yield call(userService.saveUserToken, data.tokens.refresh, 'refresh');
      yield call(userService.saveUser, data.user);
      let themeData = {};

      const isUserAdmin = yield call(isAdmin);
  
      // apply theme
      if (!isUserAdmin) {
  
        themeData = {
          primaryColor: data.user.branding.primaryColor,
          secondaryColor: data.user.branding.secondaryColor,
          textColor: data.user.branding.textColor,
          logo: data.user.branding.logo.image !== "" ? `${paths.serverPublicUrl}${data.user.branding.logo.path}${data.user.branding.logo.image}` : null,
          favicon: data.user.branding.favicon.image !== "" ? `${paths.serverPublicUrl}${data.user.branding.favicon.path}${data.user.branding.favicon.image}` : null,
        };
  
        yield call(saveTheme, 'theme', themeData);
  
      }
      yield call(userService.setIsOTPVerification, false);

      yield call(delayedRedirect, '/');
    }

  } catch (e) {
    yield put(actions.loginUserFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while logging in!');
  }
}

export function* logoutUser() {
  yield put(actions.loginUserLoading());

  try {

    userService.removeUserToken('access');
    userService.removeUserToken('refresh');
    userService.removeUser();
    yield call(removeTheme);
    localforage.clear();
    localStorage.clear()
    yield call(delayedRedirect, '/login', 1500);

  } catch (e) {
    yield put(actions.loginUserFailure(e.toString()));
    Toast.error('Some error occurred while logging out!');
  }
}

export function* requestPasswordReset(action) {
  yield put(actions.requestPasswordResetLoading());

  try {
    yield call(
      request,
      `auth/forgot-password`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.requestPasswordResetSuccess());
    Toast.success('You should receive reset password link on your registered email');
  } catch (e) {
    yield put(actions.requestPasswordResetFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(e.toString());
  }
}

export function* resetPassword(action) {
  yield put(actions.resetPasswordLoading());

  try {
    yield call(
      request,
      `auth/reset-password?token=${action.payload.token}`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.resetPasswordSuccess());
    Toast.success('Password reset successfully, please login');
    yield call(delayedRedirect, '/login');
  } catch (e) {
    yield put(actions.resetPasswordFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(e.toString());
  }
}

export function* sendOTP(action) {
  yield put(actions.sendOTPLoading());

  try {
    yield call(
      request,
      `auth/send-otp`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.sendOTPSuccess());
  } catch (e) {
    yield put(actions.sendOTPFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(e.toString());
  }
}

export function* verifyOTP(action) {
  yield put(actions.verifyOTPLoading());

  try {
    const data = yield call(
      request,
      `auth/verify-otp`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.verifyOTPSuccess());
    Toast.success('OTP verified successfully');

    yield call(userService.setIsOTPVerification, true, true);

    if (!data.user.clientCustomizationStepComplete) {
      yield call(delayedRedirect, `/complete-customization`);
    } else {
      yield call(delayedRedirect, '/');
    }


  } catch (e) {
    yield put(actions.verifyOTPFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(e.toString());
  }
}

export function* authSaga() {
  yield takeLatest(actions.registerUser.type, registerUser);
  yield takeLatest(actions.loginUser.type, loginUser);
  yield takeLatest(actions.registerUserWithAzure.type, registerUserWithAzure);
  yield takeLatest(actions.loginUserWithAzure.type, loginUserWithAzure);
  yield takeLatest(actions.logoutUser.type, logoutUser);
  yield takeLatest(actions.requestPasswordReset.type, requestPasswordReset);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.completeRegistration.type, completeRegistration);
  yield takeLatest(actions.emailVerification.type, emailVerification);
  yield takeLatest(actions.sendOTP.type, sendOTP);
  yield takeLatest(actions.verifyOTP.type, verifyOTP);
}
